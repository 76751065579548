import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SpaceplanModel, SpaceplanTabType } from '@models';
import { AppState, updateDeviceData, updateRoom, uploadRoomplan } from '@ngrx-store';
import { Store } from '@ngrx/store';
import { AppService } from '@services';
import { AsyncPipe, CommonModule, NgForOf, NgIf, NgStyle } from '@angular/common';
import { PipesModule } from '@pipes';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DeleteSpaceplanComponent } from '@standalone/_spaceplan/delete-spaceplan/delete-spaceplan.component';
import { SpaceplanAttachmentComponent } from '@standalone/_spaceplan/spaceplan-attachment/spaceplan-attachment.component';
import { Content } from '@ngneat/overview';
import { HttpClient } from '@angular/common/http';
import { SafeUrl } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  defaultDocumentTab,
  getTabMetadataFromImage,
  setTabMetadataToImage,
  spaceplanTabSelectOptions,
} from '@app-lib';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-spaceplan',
  templateUrl: './spaceplan.component.html',
  standalone: true,
  styles: [
    `
      app-spaceplan-attachment {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        width: 100%;
      }

      .img-wrapper {
        box-shadow: inset 1px 1px 0 var(--dark), inset -1px -1px 0 var(--dark);
      }

      .zoom-btn-wrapper {
        background: rgba(29, 30, 37, 0.6);
      }
    `,
  ],
  imports: [
    NgForOf,
    PipesModule,
    NgxSkeletonLoaderModule,
    DeleteSpaceplanComponent,
    NgIf,
    AsyncPipe,
    NgStyle,
    SpaceplanAttachmentComponent,
    CommonModule,
    MatSelectModule,
  ],
})
export class SpaceplanComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() spaceplan!: SpaceplanModel;
  @Input() currentAttachmentId = '';
  @Input() locationId!: string;
  @Input() isMobile = false;
  @Input() canEdit = false;
  @Input() popover: Content | null = null;
  @Input() outsideImgBlobUrl: SafeUrl | string = '';
  @Input() documentTab = defaultDocumentTab;
  @ViewChild('wrapper') wrapper: ElementRef | undefined;
  @ViewChild('img') img!: ElementRef;
  destroyRef = inject(DestroyRef);
  clientId: string;
  width = 0;
  height = 0;
  isImgLoaded = false;
  resizeObserver: ResizeObserver | undefined;
  ratio = 1;
  isRatioInitialized = false;
  imgBlob: Blob | null = null;
  imgBlobUrl: SafeUrl | string = '';
  tabSelectOptions = spaceplanTabSelectOptions;
  selectedTabValue: string | null = '';
  readonly defaultDocumentTab = defaultDocumentTab;

  isDragging = false;
  startX = 0;
  startY = 0;

  velocityX = 0;
  velocityY = 0;

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!(event.target as HTMLElement)?.classList.contains('resizable-draggable')) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.startY = event.clientY;
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging && this.wrapper && !(event.target as HTMLElement)?.classList.contains('resizable-draggable')) {
      const deltaX = event.clientX - this.startX;
      const deltaY = event.clientY - this.startY;

      this.velocityX = deltaX;
      this.velocityY = deltaY;

      requestAnimationFrame(() => {
        if (this.wrapper) {
          this.wrapper.nativeElement.scrollLeft -= deltaX;
          this.wrapper.nativeElement.scrollTop -= deltaY;
        }
      });

      this.startX = event.clientX;
      this.startY = event.clientY;
    }
  }

  onMouseUp(): void {
    this.isDragging = false;

    const wrapper = this.wrapper?.nativeElement;
    if (!wrapper) return;
    const decay = 0.95;

    const applyInertia = () => {
      if (Math.abs(this.velocityX) > 0.1 || Math.abs(this.velocityY) > 0.1) {
        wrapper.scrollLeft -= this.velocityX;
        wrapper.scrollTop -= this.velocityY;

        this.velocityX *= decay;
        this.velocityY *= decay;
        requestAnimationFrame(applyInertia);
      }
    };
    applyInertia();
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    event.preventDefault();

    if (event.deltaY > 0) {
      this.zoomIncrease();
    } else {
      this.zoomDecrease();
    }
  }

  ngOnInit() {
    document.addEventListener('mouseup', this.onMouseUp.bind(this));
  }

  constructor(
    private appService: AppService,
    private store: Store<AppState>,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private http: HttpClient
  ) {
    this.clientId = this.appService.currentClient;
  }

  get editAttachmentId() {
    return this.appService.activeAttachmentId;
  }

  get containerHeight() {
    return (this.wrapper?.nativeElement.offsetWidth || 0) / this.ratio;
  }

  get wrapperElement() {
    return this.wrapper?.nativeElement;
  }

  ngAfterViewInit() {
    if (this.img?.nativeElement) {
      this.img.nativeElement.onload = () => {
        this.isImgLoaded = true;
      };

      this.resizeObserver = new ResizeObserver(entries => {
        this.width = entries[0].contentRect.width;
        this.height = entries[0].contentRect.height;
        if (this.width && this.height && this.isImgLoaded && !this.isRatioInitialized) {
          this.ratio = this.width / this.height;
          this.isRatioInitialized = true;
        }

        this.cdr.detectChanges();
      });

      this.resizeObserver.observe(this.img.nativeElement);
      this.cdr.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['spaceplan']?.currentValue?.id !== changes['spaceplan']?.previousValue?.id) {
      this.isImgLoaded = false;
      this.isRatioInitialized = false;
      this.ratio = 1;
      this.imgBlobUrl = '';
      this.imgBlob = null;

      if (!this.outsideImgBlobUrl) {
        this.fetchSpaceplaneImage();
      }
    }
  }

  ngOnDestroy() {
    this.resizeObserver?.disconnect();
    this.resetAttachmentId();
    document.removeEventListener('mouseup', this.onMouseUp.bind(this));
  }

  confirm({ attachmentId, position }: { attachmentId: string; position: string }, isRoomAttachment: boolean) {
    this.resetAttachmentId();
    const dispatchAction = isRoomAttachment
      ? updateRoom({
          locationId: this.locationId,
          spaceId: attachmentId,
          data: { unstructuredDataReference: position },
        })
      : updateDeviceData({
          locationId: this.locationId,
          deviceId: attachmentId,
          data: { unstructuredDataReference: position },
        });

    this.store.dispatch(dispatchAction);
  }

  resetAttachmentId() {
    this.appService.activeAttachmentId = null;
  }

  minZoom = 0;
  maxZoom = 10;
  zoomPercentStep = 25;
  zoom = 0;

  isFullScreen = false;

  enterFloorplanFullScreen() {
    this.isFullScreen = true;
  }

  exitFloorplanFullScreen() {
    // if (this.wrapper) {
    //   this.wrapper.nativeElement.scrollLeft = 0;
    // }
    this.isFullScreen = false;
    this.cdr.detectChanges();
  }

  zoomIncrease() {
    if (this.zoom < this.maxZoom) {
      this.zoom += 1;
    }
  }

  zoomDecrease() {
    if (this.zoom > this.minZoom) {
      this.zoom -= 1;
    }
  }

  fetchSpaceplaneImage() {
    if (this.clientId && this.locationId && this.spaceplan.id && this.spaceplan.documentReference) {
      this.http
        .get(
          `clientId/${this.clientId}/location/${this.locationId}/space/${this.spaceplan.id}/document/${this.spaceplan.documentReference}`,
          { responseType: 'blob' }
        )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(async blob => {
          this.imgBlob = blob;
          this.imgBlobUrl = URL.createObjectURL(blob);

          const imageTab = await getTabMetadataFromImage(blob);
          this.selectedTabValue = imageTab;
        });
    }
  }

  async setImageTab() {
    if (this.imgBlob && this.spaceplan.parentSpaceId && this.spaceplan.documentReference) {
      const updatedBlob = await setTabMetadataToImage(this.imgBlob, this.selectedTabValue || '');

      if (updatedBlob) {
        const file = new File([updatedBlob], this.spaceplan.documentReference);

        this.store.dispatch(
          uploadRoomplan({
            file,
            locationId: this.locationId,
            floorId: this.spaceplan.parentSpaceId,
            roomId: this.spaceplan.id,
          })
        );
      }
    }
  }
}
