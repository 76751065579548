import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { EntityStatus, AttachmentItemModel } from '@models';
import { RouterLink } from '@angular/router';
import { TippyDirective } from '@ngneat/helipopper';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { Content } from '@ngneat/overview';
import { getAttachmentPosition, getCoordinatesByTab, isSpaceplanTabType } from '@app-lib';

export enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2,
}

@Component({
  standalone: true,
  selector: 'app-spaceplan-attachment',
  templateUrl: './spaceplan-attachment.component.html',
  styleUrls: ['./spaceplan-attachment.component.scss'],
  imports: [RouterLink, TippyDirective, MatIcon, NgIf],
})
export class SpaceplanAttachmentComponent implements OnChanges, OnDestroy {
  @ViewChild('box') box: ElementRef | undefined;
  // Container's dimension must be provided in px
  @Input() containerWidth = 0;
  @Input() containerHeight = 0;
  @Input() editMode = false;
  @Input() attachment!: AttachmentItemModel;
  @Input() routerLink: string[] = [];
  @Input() allowNavigation = true;
  @Input() popover: Content | null = null;
  @Input() popoverData: any;
  @Input() documentTab: string | undefined;
  @Output() confirmEvent = new EventEmitter<{ attachmentId: string; position: string }>();
  @Output() cancelEvent = new EventEmitter();
  @Output() navigationEvent = new EventEmitter<string>();
  protected readonly Status = Status;

  entityStatus = EntityStatus;
  // internal values (px)
  width = 0;
  height = 0;
  left = 0;
  top = 0;
  // internal values (%)
  widthPercent = 0;
  heightPercent = 0;
  leftPercent = 0;
  topPercent = 0;

  minSize = 15;

  boxPosition: { left: number; top: number } = { left: 0, top: 0 };
  containerPos: { left: number; top: number; right: number; bottom: number } = {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  };
  mouse: { x: number; y: number } = { x: 0, y: 0 };
  mouseClick: { x: number; y: number; left: number; top: number } = {
    x: 0,
    y: 0,
    left: 0,
    top: 0,
  };
  status: Status = Status.OFF;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['containerHeight']?.currentValue || changes['containerWidth']?.currentValue) {
      this.refreshAttachmentPosition();
    }
    if (changes['editMode']?.currentValue) {
      if (!getCoordinatesByTab(this.attachment.unstructuredDataReference, this.documentTab)) {
        this.width = 100;
        this.height = 100;
        this.widthPercent = this.pxToPercentX(100);
        this.heightPercent = this.pxToPercentY(100);
      }
    }
  }

  ngOnDestroy() {
    window.removeEventListener('mousemove', this.onMouseMove);
  }

  refreshAttachmentPosition() {
    this.loadBox();
    this.loadContainer();
    const coordinates = getCoordinatesByTab(this.attachment.unstructuredDataReference, this.documentTab);
    if (coordinates) {
      this.width = this.percentXToPx(coordinates.width);
      this.left = this.percentXToPx(coordinates.left);
      this.height = this.percentYToPx(coordinates.height);
      this.top = this.percentYToPx(coordinates.top);
    } else {
      this.width = this.percentXToPx(this.widthPercent);
      this.left = this.percentXToPx(this.leftPercent);
      this.height = this.percentYToPx(this.heightPercent);
      this.top = this.percentYToPx(this.topPercent);
    }
  }

  percentXToPx(percent: number): number {
    return Math.round((this.containerWidth * percent) / 100);
  }

  percentYToPx(percent: number): number {
    return Math.round((this.containerHeight * percent) / 100);
  }

  pxToPercentX(value: number): number {
    return Number(((value * 100) / this.containerWidth).toFixed(2));
  }

  pxToPercentY(value: number): number {
    return Number(((value * 100) / this.containerHeight).toFixed(2));
  }

  loadBox() {
    if (this.box) {
      const { left, top } = this.box.nativeElement.getBoundingClientRect();
      this.boxPosition = { left, top };
    }
  }

  loadContainer() {
    const left = this.boxPosition.left - this.left;
    const top = this.boxPosition.top - this.top;
    const right = left + this.containerWidth;
    const bottom = top + this.containerHeight;
    this.containerPos = { left, top, right, bottom };
  }

  updatePercentValues() {
    this.leftPercent = this.pxToPercentX(this.left);
    this.widthPercent = this.pxToPercentX(this.width);
    this.heightPercent = this.pxToPercentY(this.height);
    this.topPercent = this.pxToPercentY(this.top);
  }

  setStatus(event: MouseEvent, status: number) {
    if (status === Status.RESIZE) {
      event.stopPropagation();
      this.loadBox();
    } else if (status === Status.MOVE) {
      this.mouseClick = {
        x: event.clientX,
        y: event.clientY,
        left: this.left,
        top: this.top,
      };
    } else {
      this.loadBox();
      this.updatePercentValues();
    }

    this.status = status;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mouse = { x: event.clientX, y: event.clientY };
    // const targetElement = event.target as HTMLElement;

    if (this.status === Status.RESIZE) {
      // TODO: update commented code so that it could reset status when mouse out of the attachment
      // if (targetElement?.classList.contains('resize-action')) {
      this.resize();
      // } else {
      //   this.status = Status.OFF;
      // }
    } else if (this.status === Status.MOVE) {
      // if (targetElement?.classList.contains('resizable-draggable')) {
      this.move();
      // } else {
      //   this.status = Status.OFF;
      // }
    }
  }

  resize() {
    let width = this.mouse.x - this.boxPosition.left;
    if (this.left + width > this.containerWidth) {
      width = this.containerWidth - this.left;
    }

    let height = this.mouse.y - this.boxPosition.top;
    if (this.top + height > this.containerHeight) {
      height = this.containerHeight - this.top;
    }

    this.width = Number(this.mouse.x > this.boxPosition.left + this.minSize) ? width : this.minSize;
    this.height = Number(this.mouse.y > this.boxPosition.top + this.minSize) ? height : this.minSize;
  }

  move() {
    let left = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
    if (left < 0) {
      left = 0;
    }
    if (left + this.width > this.containerPos.right - this.containerPos.left) {
      left = this.containerPos.right - this.containerPos.left - this.width;
    }
    this.left = left;

    let top = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);
    if (top < 0) {
      top = 0;
    }
    if (top + this.height > this.containerPos.bottom - this.containerPos.top) {
      top = this.containerPos.bottom - this.containerPos.top - this.height;
    }
    this.top = top;
  }

  confirm() {
    const fullPositionInfo = getAttachmentPosition(this.attachment.unstructuredDataReference);

    const currentPosition = {
      width: this.widthPercent,
      left: this.leftPercent,
      height: this.heightPercent,
      top: this.topPercent,
    };

    let coordinates;

    if (this.documentTab && isSpaceplanTabType(this.documentTab)) {
      coordinates = {
        ...(fullPositionInfo?.coordinates || {}),
        [this.documentTab]: currentPosition,
      };
    } else {
      coordinates = { ...currentPosition };
    }
    this.confirmEvent.emit({ attachmentId: this.attachment.id, position: JSON.stringify({ coordinates }) });
  }

  cancel() {
    const coordinates = getCoordinatesByTab(this.attachment.unstructuredDataReference, this.documentTab);
    if (coordinates) {
      this.width = this.percentXToPx(coordinates.width);
      this.left = this.percentXToPx(coordinates.left);
      this.height = this.percentYToPx(coordinates.height);
      this.top = this.percentYToPx(coordinates.top);
    } else {
      this.width = 0;
      this.height = 0;
      this.top = 0;
      this.left = 0;
    }
    this.updatePercentValues();
    this.cancelEvent.emit(this.attachment.id);
  }
}
