import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptySpacesBuildingPlan',
})
export class EmptySpacesBuildingPlanPipe implements PipeTransform {
  minSpaceCount = 40;

  transform(roomsCount = 0, emptyItems = this.minSpaceCount): number[] {
    if (emptyItems < roomsCount) return [];
    return Array.from(Array(emptyItems - roomsCount));
  }
}
