import { Component, DestroyRef, Input, OnDestroy, OnInit, ViewChild, inject, ChangeDetectorRef } from '@angular/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { ClientUser, UserRolesIds, UserStatus } from '@models';
import {
  AppState,
  UserState,
  getCompanyUserList,
  resendUserInvitation,
  selectAuthState,
  updateUserClient,
  userListIsLoaded,
  userRole,
} from '@ngrx-store';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { naturalSorting } from '@app-lib';
import { ConfirmationDialogComponent } from '@standalone/_modals/confirmation-dialog/confirmation-dialog.component';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { DirectivesModule, VirtualTableScrollNormalizerDirective } from '@directives';
import { AsyncPipe, DatePipe, NgClass, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { SearchInputComponent } from '@standalone/search-input/search-input.component';
import { SkeletonTableComponent } from '@standalone/skeleton-table/skeleton-table.component';
import { NoDataComponent } from '@standalone/no-data/no-data.component';
import { MatTooltip } from '@angular/material/tooltip';
import { PipesModule } from '@pipes';
import { UserStatusComponent } from '@standalone/user-status/user-status.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

type UserStatusFilter = 'current' | 'archived';

@Component({
  standalone: true,
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
  imports: [
    MatButtonToggleGroup,
    MatButtonToggle,
    DirectivesModule,
    NgIf,
    RouterLink,
    MatIcon,
    SearchInputComponent,
    AsyncPipe,
    MatSort,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatCellDef,
    MatHeaderCellDef,
    NgClass,
    SkeletonTableComponent,
    NoDataComponent,
    MatHeaderRow,
    MatRow,
    MatTooltip,
    DatePipe,
    PipesModule,
    UserStatusComponent,
    MatSortHeader,
    MatRowDef,
    MatHeaderRowDef,
    CdkFixedSizeVirtualScroll,
    CdkVirtualScrollViewport,
    VirtualTableScrollNormalizerDirective,
  ],
})
export class UsersTableComponent implements OnDestroy, OnInit {
  @Input() tableId: string | null = null;
  @Input() isMobile = false;
  @Input() isInjected = false;
  protected readonly ClientUserStatus = UserStatus;
  @ViewChild(MatSort) sort!: MatSort;

  editor$: Observable<UserState> | undefined;
  dataSource: MatTableDataSource<ClientUser> = new MatTableDataSource<ClientUser>([]);
  displayedColumns: string[] = ['index', 'firstName', 'status', 'role', 'email', 'phoneNumber', 'lastLogin', 'archive'];
  userStatusFilter: UserStatusFilter = 'current';
  userTextFilter!: string;
  userListSub$!: Subscription;
  userListLoaded$!: Observable<boolean>;
  destroyRef = inject(DestroyRef);
  @ViewChild('tableContent') set paginatorBlock(element: MatTable<any>) {
    if (element) {
      this.sortInit();
      this.changeDetectionRef.detectChanges();
    }
  }
  userRole$: Observable<UserRolesIds | undefined>;
  userRolesIds = UserRolesIds;

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private changeDetectionRef: ChangeDetectorRef
  ) {
    this.userRole$ = this.store.select(userRole);
  }

  ngOnInit(): void {
    this.userListLoaded$ = this.store.select(userListIsLoaded);
    this.editor$ = this.store.select(selectAuthState);
    this.userListSub$ = this.store.select(getCompanyUserList).subscribe(users => {
      this.dataSource = new MatTableDataSource<ClientUser>(users);
      this.dataSource.filterPredicate = this.filterLogic;
      this.sortInit();
      this.filterTable();
    });
  }

  private sortInit() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'role' && item.role) {
        return item.role.name;
      }
      const props = item[property as keyof ClientUser];
      return props as string;
    };
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = naturalSorting;
  }

  filterLogic(record: ClientUser, filter: string) {
    const filterData = JSON.parse(filter);
    const statusMatch =
      filterData.userStatusFilter === 'archived'
        ? record.status === UserStatus.ARCHIVED
        : record.status !== UserStatus.ARCHIVED;
    const nameFilter =
      !filterData.userTextFilter ||
      (record.firstName + ' ' + record.lastName + ' ' + record.email)
        .toLowerCase()
        .includes(filterData.userTextFilter.toLowerCase());

    return statusMatch && nameFilter;
  }

  filterTable() {
    this.dataSource.filter = JSON.stringify({
      userStatusFilter: this.userStatusFilter,
      userTextFilter: this.userTextFilter,
    });
  }

  ngOnDestroy(): void {
    this.userListSub$?.unsubscribe();
  }

  userSearch(user: string) {
    this.userTextFilter = user;
    this.filterTable();
  }

  onStatusFilterChange(value: UserStatusFilter) {
    if (value) {
      this.userStatusFilter = value;
      this.filterTable();
    }
  }

  openConfirmationDialog({
    userId,
    title,
    description,
    data,
  }: {
    userId: string;
    title: string;
    description: string;
    data: Partial<ClientUser>;
  }) {
    ConfirmationDialogComponent.open(this.dialog, {
      title,
      description,
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(confirmation => {
        if (confirmation) {
          this.store.dispatch(updateUserClient({ clientUserId: userId, clientUser: data }));
        }
      });
  }

  archiveUser(user: ClientUser) {
    this.openConfirmationDialog({
      userId: user.id,
      title: 'Archive',
      description: `Do you really want to archive ${user.firstName} ${user.lastName} ?`,
      data: { isArchived: true },
    });
  }

  restoreUser(user: ClientUser) {
    this.openConfirmationDialog({
      userId: user.id,
      title: 'Restore',
      description: `Do you really want to restore ${user.firstName} ${user.lastName} ?`,
      data: { isArchived: false },
    });
  }

  resendInvitation(user: ClientUser) {
    this.store.dispatch(resendUserInvitation({ clientUser: user }));
  }
}
